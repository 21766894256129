import { gql, useQuery } from '@apollo/client';
import { REQUEST_CONTEXT_SAMPLE_RATE } from '@rmvw/c-common';
import * as React from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';

import CourbetDarkColorTheme from '../../providers/ThemeProvider/color-themes/CourbetDarkColorTheme';
import { CanonicalSize } from '../../providers/ThemeProvider/themes';
import DiscussionProfileIcon from '../Discussion/DiscussionProfileIcon';
import { LinkBrokenIcon } from '../Icon';

import {
  CF_ProfileAvatar,
  ProfileAvatarProfile,
  ProfileAvatarProfileVariables,
} from './___generated___/ProfileAvatar.types';
import { Avatar, IAvatarProps } from './Avatar';

export function getAvatarProps(
  theme: DefaultTheme,
  profile: CF_ProfileAvatar,
  size: number | undefined,
  iconSize?: CanonicalSize | number
) {
  // Use the profile's id to choose a random color
  const avatarColors = theme.color.avatarColors;
  const randomIndex =
    Math.abs(profile.id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)) % avatarColors.length;
  const backgroundColor = avatarColors[randomIndex];

  // Use iconSize if it exists. Otherwise determine iconSize based on whether the profile has a background
  const hasBackground = profile.__typename === 'Team';
  const scaleFactor = hasBackground ? 0.6 : 0.8;
  const _iconSize = iconSize ? iconSize : size ? size * scaleFactor : undefined;

  switch (profile.__typename) {
    case 'PrivateChat': {
      return {
        backgroundColor,
        color: CourbetDarkColorTheme.background800,
        src: <DiscussionProfileIcon size={_iconSize} type={profile.icon} />,
      };
    }

    case 'Discussion':
    case 'Meeting':
    case 'Replies': {
      return {
        backgroundColor: 'transparent',
        color: theme.color.lightPurple,
        src: <DiscussionProfileIcon size={_iconSize} type={profile.icon} />,
      };
    }

    case 'Team': {
      return {
        backgroundColor,
        color: CourbetDarkColorTheme.background800,
        src: <DiscussionProfileIcon size={_iconSize} strokeWidth={2} type={profile.icon} />,
      };
    }

    default: {
      return {
        backgroundColor,
        color: CourbetDarkColorTheme.background800,
        src: profile.profileImage?.url,
      };
    }
  }
}

export interface IProfileAvatarProps extends Omit<IAvatarProps, 'src'> {
  iconSize?: CanonicalSize | number; // Used to override default icon size determined by size
  profile: CF_ProfileAvatar | null | undefined;
}

export function ProfileAvatar({ profile, size, iconSize, ...props }: IProfileAvatarProps) {
  const theme = useTheme();

  if (!profile) {
    // TODO: better fallback
    return <Avatar {...props} backgroundColor={'red'} color={'white'} size={size} src={<LinkBrokenIcon />} />;
  }

  const { backgroundColor, color, src } = getAvatarProps(theme, profile, size, iconSize);

  return (
    <_Avatar
      {...props}
      alt={props.alt ?? profile.name}
      backgroundColor={backgroundColor}
      color={color}
      size={size}
      src={src}
    />
  );
}

ProfileAvatar.fragment = gql`
  fragment CF_ProfileAvatar on IProfile {
    id
    icon
    name
    profileImage {
      id
      url(size: { width: 256, height: 256 })
    }
  }
`;

ProfileAvatar.query = gql`
  ${ProfileAvatar.fragment}
  query ProfileAvatarProfile($id: ID!) {
    profile(id: $id) {
      id
      ...CF_ProfileAvatar
    }
  }
`;

///

export interface IProfileAvatarByIdProps extends Omit<IProfileAvatarProps, 'profile'> {
  id: string;
}

export function ProfileAvatarById({ id, ...props }: IProfileAvatarByIdProps) {
  const { data, loading } = useQuery<ProfileAvatarProfile, ProfileAvatarProfileVariables>(ProfileAvatar.query, {
    variables: { id },
    context: { [REQUEST_CONTEXT_SAMPLE_RATE]: 0 },
  });
  return <ProfileAvatar {...props} isLoading={loading} profile={data?.profile} />;
}

const _Avatar = styled(Avatar)`
  align-items: center;
  display: flex;
  justify-content: center;
`;
